import React from "react";
import { getFullVersion } from "../config/version";
import { Text } from "@chakra-ui/react";

const VersionDisplay = () => {
  return (
    <Text
      fontSize="sm"
      color="gray.500"
      position="fixed"
      bottom="2"
      right="2"
    >
      {getFullVersion()}
    </Text>
  );
};

export default VersionDisplay;
