import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

interface HeaderState {
  showNavigationButtons: boolean;
  showProfileButton: boolean;
  showAiButton: boolean;
  showEditButton: boolean;
  aiButtonClick: boolean;
  editButtonClick: boolean;
  profileButtonClick: boolean;
  isAiButtonHighlighted: boolean;
  isUsingCloudTTS: boolean;
}

const initialState: HeaderState = {
  showNavigationButtons: false,
  showProfileButton: false,
  showAiButton: false,
  showEditButton: false,
  aiButtonClick: false,
  editButtonClick: false,
  profileButtonClick: false,
  isAiButtonHighlighted: false,
  isUsingCloudTTS: false,
};

const headerState = createSlice({
  name: "header",
  initialState,
  reducers: {
    setShowNavigationButtonsAction: (state, action: PayloadAction<boolean>) => {
      state.showNavigationButtons = action.payload;
    },
    setShowProfileButtonAction: (state, action: PayloadAction<boolean>) => {
      state.showProfileButton = action.payload;
    },
    setShowAiButtonAction: (state, action: PayloadAction<boolean>) => {
      state.showAiButton = action.payload;
    },
    setShowEditButtonAction: (state, action: PayloadAction<boolean>) => {
      state.showEditButton = action.payload;
    },
    setAiButtonClickAction: (state, action: PayloadAction<boolean>) => {
      state.aiButtonClick = action.payload;
    },
    setEditButtonClickAction: (state, action: PayloadAction<boolean>) => {
      state.editButtonClick = action.payload;
    },
    setProfileButtonClickAction: (state, action: PayloadAction<boolean>) => {
      state.profileButtonClick = action.payload;
    },
    setAiButtonHighlightedAction: (state, action: PayloadAction<boolean>) => {
      // New reducer for highlighting
      state.isAiButtonHighlighted = action.payload;
    },
    setIsUsingCloudTTSAction: (state, action: PayloadAction<boolean>) => {
      state.isUsingCloudTTS = action.payload;
    },
  },
});

export const {
  setShowNavigationButtonsAction,
  setShowProfileButtonAction,
  setShowAiButtonAction,
  setShowEditButtonAction,
  setAiButtonClickAction,
  setEditButtonClickAction,
  setProfileButtonClickAction,
  setAiButtonHighlightedAction,
  setIsUsingCloudTTSAction,
} = headerState.actions;
export const headerReducer = headerState.reducer;

const selectHeaderState = (state: any) => state.header;
export const selectShowNavigationButtons = createSelector(
  [selectHeaderState],
  (header) => header.showNavigationButtons,
);
export const selectShowProfileButton = createSelector(
  [selectHeaderState],
  (header) => header.showProfileButton,
);
export const selectShowAiButton = createSelector(
  [selectHeaderState],
  (header) => header.showAiButton,
);
export const selectShowEditButton = createSelector(
  [selectHeaderState],
  (header) => header.showEditButton,
);
export const selectAiButtonClick = createSelector(
  [selectHeaderState],
  (header) => header.aiButtonClick,
);
export const selectEditButtonClick = createSelector(
  [selectHeaderState],
  (header) => header.editButtonClick,
);
export const selectProfileButtonClick = createSelector(
  [selectHeaderState],
  (header) => header.profileButtonClick,
);

export const selectAiButtonHighlighted = createSelector(
  [selectHeaderState],
  (header) => header.isAiButtonHighlighted,
);

export const selectIsUsingCloudTTS = createSelector(
  [selectHeaderState],
  (header) => header.isUsingCloudTTS,
);

export const useHeaderState = () => {
  const dispatch = useDispatch();

  const setShowNavigationButtons = (show: boolean) => {
    dispatch(setShowNavigationButtonsAction(show));
  };

  const setShowProfileButton = (show: boolean) => {
    dispatch(setShowProfileButtonAction(show));
  };

  const setShowAiButton = (show: boolean) => {
    dispatch(setShowAiButtonAction(show));
  };

  const setShowEditButton = (show: boolean) => {
    dispatch(setShowEditButtonAction(show));
  };

  const triggerAiButtonClick = (state: boolean) => {
    dispatch(setAiButtonClickAction(state));
  };

  const triggerEditButtonClick = (state: boolean) => {
    dispatch(setEditButtonClickAction(state));
  };

  const triggerProfileButtonClick = (state: boolean) => {
    dispatch(setProfileButtonClickAction(state));
  };

  const highlightAiButton = (highlight: boolean) => {
    dispatch(setAiButtonHighlightedAction(highlight));
  };

  const setIsUsingCloudTTS = (isUsingCloud: boolean) => {
    dispatch(setIsUsingCloudTTSAction(isUsingCloud));
  };

  const showNavigationButtons = useSelector(selectShowNavigationButtons);
  const showProfileButton = useSelector(selectShowProfileButton);
  const showAiButton = useSelector(selectShowAiButton);
  const showEditButton = useSelector(selectShowEditButton);
  const aiButtonClick = useSelector(selectAiButtonClick);
  const editButtonClick = useSelector(selectEditButtonClick);
  const profileButtonClick = useSelector(selectProfileButtonClick);
  const isAiButtonHighlighted = useSelector(selectAiButtonHighlighted);
  const isUsingCloudTTS = useSelector(selectIsUsingCloudTTS);

  return {
    setShowNavigationButtons,
    setShowProfileButton,
    setShowAiButton,
    setShowEditButton,
    showNavigationButtons,
    showProfileButton,
    showAiButton,
    showEditButton,
    triggerAiButtonClick,
    triggerProfileButtonClick,
    triggerEditButtonClick,
    aiButtonClick,
    editButtonClick,
    profileButtonClick,
    highlightAiButton,
    isAiButtonHighlighted,
    isUsingCloudTTS,
    setIsUsingCloudTTS,
  };
};

export default useHeaderState;
