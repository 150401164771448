import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Text,
  useDisclosure,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  SettingsIcon,
  EditIcon,
  InfoIcon,
  CheckIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { ImExit, ImEnter } from "react-icons/im";
import useAuth from "../store/auth/authState";
import EditModal from "./dialogs/EditModal";
import useAppMode from "../store/appMode/appModeState";
import useI18n from "../hooks/useI18n";
import { useLogoutMutation } from "../modules/auth/hooks/useLogoutMutation";
import { FiLogOut } from "react-icons/fi";
import Settings from "../modules/accountAndSettings/components/Settings";
import Profile from "../modules/accountAndSettings/components/Profile";
import { useIsMobile } from "../hooks/useIsMobile";
import { usePWAStatus } from "../hooks/usePWAStatus";
import { AddIcon } from "@chakra-ui/icons";
import { HeaderStatus } from "./HeaderStatus";
import TTSIndicator from "./TTSIndicator";
import useHeaderState from "../store/header/headerState";

interface HeaderProps {
  showEdit?: boolean;
  bgColor?: string;
}

const Header: React.FC<HeaderProps> = ({
  showEdit = true,
  bgColor = "white",
}) => {
  const { isUsingCloudTTS } = useHeaderState();

  const navigate = useNavigate();
  const { tokens } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isEditMode, toggleEditMode } = useAppMode();
  const { t } = useI18n("auth");
  const { mutate: logout } = useLogoutMutation();
  const { canInstallPWA, isSafari, promptInstall } = usePWAStatus();
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();
  const {
    isOpen: isProfileOpen,
    onOpen: onProfileOpen,
    onClose: onProfileClose,
  } = useDisclosure();
  const {
    isOpen: isInstallModalOpen,
    onOpen: onInstallModalOpen,
    onClose: onInstallModalClose,
  } = useDisclosure();
  const { isMobile } = useIsMobile();

  const isAuthenticated = !!tokens.accessToken;

  const handleGoHome = () => {
    navigate("/home");
  };

  const handleProfileClick = () => {
    if (isAuthenticated && isMobile) {
      onProfileOpen();
    } else if (isAuthenticated) {
      navigate("/account/info");
    } else {
      onOpen();
    }
  };

  const handleSettingsClick = () => {
    if (isAuthenticated && isMobile) {
      onSettingsOpen();
    } else if (isAuthenticated) {
      navigate("/account/settings");
    } else {
      onOpen();
    }
  };

  const handleEditClick = () => {
    if (isAuthenticated) {
      toggleEditMode();
    } else {
      onOpen();
    }
  };

  const handleLogout = () => {
    logout();
    if (isEditMode) {
      toggleEditMode();
    }
  };

  const handleLogIn = () => {
    navigate("/auth/sign-in");
  };

  const handleInstall = async () => {
    if (isSafari) {
      onInstallModalOpen();
    } else {
      await promptInstall();
    }
  };

  const renderSafariInstructions = () => (
    <Text
      dangerouslySetInnerHTML={{ __html: t("pwa.safari_install_instructions") }}
    ></Text>
  );

  const currentBgColor = isEditMode ? "teal.500" : bgColor;
  const iconColor = isEditMode ? "white" : "gray.700";

  return (
    <>
      <Box
        h="30px"
        px={3}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px"
        borderColor="gray.200"
        backgroundColor={currentBgColor}
        position="relative"
      >
        {/* Left Side - Navigation Icons */}
        <HStack spacing={1} visibility="hidden">
          <IconButton
            aria-label="Home"
            icon={<HamburgerIcon color={iconColor} />}
            size="s"
            variant="ghost"
            // onClick={handleGoHome}
            // _hover={{
            //   backgroundColor: "transparent",
            //   color: "white",
            // }}
          />
        </HStack>

        <HeaderStatus
          isAuthenticated={isAuthenticated}
          isEditMode={isEditMode}
          t={t}
        />

        {/* Right Side - Menu or Done Button */}
        <HStack spacing={5}>
          <TTSIndicator isUsingCloud={isUsingCloudTTS} />
          {isEditMode ? (
            <IconButton
              aria-label="Complete Editing"
              icon={<CheckIcon color="white" />}
              size="m"
              variant="ghost"
              onClick={handleEditClick}
              _hover={{
                backgroundColor: "transparent",
                color: "white",
                transform: "scale(1.15)",
              }}
            />
          ) : (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<SettingsIcon color={iconColor} />}
                variant="ghost"
                size="m"
                _hover={{
                  transform: "scale(1.15)",
                }}
                onFocus={(e) => e.target.blur()} // Prevents button from staying selected
              />
              <MenuList autoFocus={false}>
                {" "}
                {/* Prevents automatic focus */}
                {isAuthenticated ? (
                  <>
                    {showEdit && (
                      <MenuItem icon={<EditIcon />} onClick={handleEditClick}>
                        {t("edit_category")}
                      </MenuItem>
                    )}
                    <MenuItem icon={<InfoIcon />} onClick={handleProfileClick}>
                      {t("profile")}
                    </MenuItem>
                    <MenuItem
                      icon={<SettingsIcon />}
                      onClick={handleSettingsClick}
                    >
                      {t("settings")}
                    </MenuItem>
                    {canInstallPWA && (
                      <MenuItem icon={<AddIcon />} onClick={handleInstall}>
                        {t("install_app")}
                      </MenuItem>
                    )}
                    <MenuItem icon={<ImExit />} onClick={handleLogout}>
                      {t("logout")}
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      icon={<SettingsIcon />}
                      onClick={handleSettingsClick}
                    >
                      {t("settings")}
                    </MenuItem>
                    {canInstallPWA && (
                      <MenuItem icon={<AddIcon />} onClick={handleInstall}>
                        {t("install_app")}
                      </MenuItem>
                    )}
                    <MenuItem icon={<ImEnter />} onClick={handleLogIn}>
                      {t("login")}
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          )}
        </HStack>
      </Box>

      {/* Install Modal for Safari */}
      <Modal
        isOpen={isInstallModalOpen}
        onClose={onInstallModalClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>{renderSafariInstructions()}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onInstallModalClose}>
              {t("pwa.got_it")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isSettingsOpen} onClose={onSettingsClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Settings />
          </ModalBody>
          <ModalFooter>
            <Flex
              justifyContent="space-between"
              w="100%"
              px={8}
              mb={10}
              gap={10}
            >
              <Button
                leftIcon={<FiLogOut />}
                colorScheme="red"
                size="lg"
                onClick={handleLogout}
                px={8}
                alignItems="center"
              >
                {t("logout")}
              </Button>
              <Button
                rightIcon={<CloseIcon />}
                onClick={onSettingsClose}
                colorScheme="gray"
                size="lg"
                px={8}
                alignItems="center"
              >
                {t("close_button")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isProfileOpen} onClose={onProfileClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Profile />
          </ModalBody>
          <ModalFooter>
            <Flex
              justifyContent="space-between"
              w="100%"
              px={8}
              mb={10}
              gap={10}
            >
              <Button
                leftIcon={<FiLogOut />}
                colorScheme="red"
                size="lg"
                onClick={handleLogout}
                px={8}
                alignItems="center"
              >
                {t("logout")}
              </Button>
              <Button
                rightIcon={<CloseIcon />}
                onClick={onProfileClose}
                colorScheme="gray"
                size="lg"
                px={8}
                alignItems="center"
              >
                {t("close_button")}
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <EditModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Header;
