import React, { useEffect, useState, useCallback } from "react";
import { Alert, AlertIcon, Button, Box } from "@chakra-ui/react";
import useI18n from "../hooks/useI18n";

const UpdateNotification = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null,
  );
  const { t } = useI18n("categories");

  const checkForUpdates = useCallback(
    async (registration: ServiceWorkerRegistration) => {
      try {
        // Force a check for updates
        await registration.update();

        // Check if there's a waiting worker
        if (registration.waiting) {
          setWaitingWorker(registration.waiting);
          setShowReload(true);
          return true;
        }

        return false;
      } catch (error) {
        console.error("Update check failed:", error);
        return false;
      }
    },
    [],
  );

  useEffect(() => {
    if (!("serviceWorker" in navigator)) return;

    let updateCheckInterval: number;
    const CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes

    const handleServiceWorkerUpdate = (
      registration: ServiceWorkerRegistration,
    ) => {
      registration.addEventListener("updatefound", () => {
        const newWorker = registration.installing;
        if (!newWorker) return;

        newWorker.addEventListener("statechange", () => {
          // When the new service worker is installed and waiting
          if (
            newWorker.state === "installed" &&
            navigator.serviceWorker.controller
          ) {
            setWaitingWorker(newWorker);
            setShowReload(true);
          }
        });
      });

      // Initial check for existing waiting worker
      if (registration.waiting) {
        setWaitingWorker(registration.waiting);
        setShowReload(true);
      }
    };

    const setupServiceWorker = async () => {
      try {
        const registration = await navigator.serviceWorker.ready;

        handleServiceWorkerUpdate(registration);

        // Set up periodic checks
        updateCheckInterval = window.setInterval(() => {
          checkForUpdates(registration);
        }, CHECK_INTERVAL);

        // Check for updates when the page becomes visible
        const handleVisibilityChange = () => {
          if (document.visibilityState === "visible" && !showReload) {
            checkForUpdates(registration);
          }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        // Initial check
        checkForUpdates(registration);

        return () => {
          clearInterval(updateCheckInterval);
          document.removeEventListener(
            "visibilitychange",
            handleVisibilityChange,
          );
        };
      } catch (error) {
        console.error("Service worker setup failed:", error);
      }
    };

    setupServiceWorker();
  }, [checkForUpdates, showReload]);

  const handleReload = useCallback(() => {
    if (waitingWorker) {
      // Send message to service worker to skip waiting
      waitingWorker.postMessage({ type: "SKIP_WAITING" });

      // Add a listener for the controlling change
      navigator.serviceWorker.addEventListener(
        "controllerchange",
        () => {
          // Reload once the new service worker takes control
          window.location.reload();
        },
        { once: true },
      );
    } else {
      // Fallback to simple reload if no waiting worker
      window.location.reload();
    }
  }, [waitingWorker]);

  if (!showReload) return null;

  return (
    <Box position="fixed" bottom={4} right={4} zIndex={1000}>
      <Alert status="info" borderRadius="md">
        <AlertIcon />
        {t("notifications.message")}
        <Button ml={4} colorScheme="blue" size="sm" onClick={handleReload}>
          {t("notifications.button")}
        </Button>
      </Alert>
    </Box>
  );
};

export default UpdateNotification;
