import React, { useState, useEffect } from "react";
import { Box, Tooltip, Icon, useColorModeValue } from "@chakra-ui/react";
import { MdCloud, MdLaptopMac } from "react-icons/md";
import useI18n from "../hooks/useI18n";

export interface TTSIndicatorProps {
  isUsingCloud: boolean;
}

const TTSIndicator: React.FC<TTSIndicatorProps> = ({ isUsingCloud }) => {
  const { t } = useI18n("common");
  const tooltipLabel = isUsingCloud
    ? t("tts.service_cloud")
    : t("tts.service_device");

  const iconColor = isUsingCloud ? "blue.400" : "yellow.400";
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  // Close tooltip after 5 seconds when it's opened
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isTooltipOpen) {
      timeout = setTimeout(() => setIsTooltipOpen(false), 5000);
    }
    return () => clearTimeout(timeout);
  }, [isTooltipOpen]);

  // Close tooltip when clicking anywhere else
  useEffect(() => {
    const handleClickOutside = () => setIsTooltipOpen(false);
    document.addEventListener("touchstart", handleClickOutside);
    return () => document.removeEventListener("touchstart", handleClickOutside);
  }, []);

  return (
    <Tooltip
      label={tooltipLabel}
      placement="bottom"
      hasArrow
      isOpen={isTooltipOpen || undefined} // undefined allows default hover behavior on desktop
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        transition="color 0.2s ease-in-out"
        color={iconColor}
        onClick={(e) => e.stopPropagation()} // Prevent unintended interactions
        onTouchStart={(e) => {
          e.stopPropagation(); // Prevent tooltip from closing immediately
          setIsTooltipOpen(true);
        }}
      >
        <Icon as={isUsingCloud ? MdCloud : MdLaptopMac} boxSize="14px" />
      </Box>
    </Tooltip>
  );
};

export default TTSIndicator;
