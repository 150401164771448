import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Spinner,
  Avatar,
  Input,
  Button,
  Flex,
  Textarea,
  Select,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useGetProfileAPI } from "../hooks/useGetProfileAPI";
import { useUpdateProfileAPI } from "../hooks/useUpdateProfileAPI";
import { format, parseISO } from "date-fns";
import useI18n from "../../../hooks/useI18n";

const Profile: React.FC = () => {
  const { data: profile, isLoading, error } = useGetProfileAPI();
  const { mutate: updateProfile } = useUpdateProfileAPI();
  const [isEditMode, setIsEditMode] = useState(false);
  const { t } = useI18n("account");
  const [formData, setFormData] = useState({
    name: "",
    birthdate: "",
    sex: "",
    bio: "",
  });

  // Responsive styles
  const avatarSize = useBreakpointValue({ base: "lg", md: "xl" });
  const nameSize = useBreakpointValue({ base: "xl", md: "2xl" });
  const spacing = useBreakpointValue({ base: 8, md: 12 });
  const padding = useBreakpointValue({ base: 4, md: 8 });

  useEffect(() => {
    if (profile) {
      setFormData({
        name: profile.name || "",
        birthdate: profile.birthdate || "",
        sex: profile.sex || "",
        bio: profile.bio || "",
      });
    }
  }, [profile]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleEditMode = () => {
    setIsEditMode((prevMode) => !prevMode);
  };

  const saveChanges = () => {
    updateProfile(formData);
    setIsEditMode(false);
  };

  if (isLoading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return <Text color="red.500">Error fetching profile data</Text>;
  }

  if (!profile) {
    return <Text>No profile data available</Text>;
  }

  return (
    <Box
      w={"100%"}
      h={"100%"}
      mx="auto"
      p={padding}
      bg="white"
      overflow="hidden"
      mb={8}
    >
      <VStack spacing={spacing} align="flex-start">
        <HStack spacing={spacing}>
          <Avatar
            size={avatarSize}
            name={profile.name || profile.email}
            src={profile.avatarUrl}
            boxShadow="md"
          />
          <VStack align="flex-start" spacing={2}>
            {isEditMode ? (
              <Input
                name="name"
                value={formData.name || profile.email}
                onChange={handleChange}
                size="lg"
                variant="flushed"
                placeholder={t("profile.name")}
              />
            ) : (
              <Text fontSize={nameSize} fontWeight="bold">
                {profile.name || profile.email}
              </Text>
            )}
            {isEditMode ? (
              <Input
                name="birthdate"
                type="date"
                value={formData.birthdate}
                onChange={handleChange}
                size="md"
                variant="flushed"
                placeholder={t("profile.birthdate")}
              />
            ) : (
              <Text fontSize="md" color="gray.500">
                {t("profile.birthdate")}:{" "}
                {profile.birthdate
                  ? format(parseISO(profile.birthdate), "MMM dd, yyyy")
                  : "N/A"}
              </Text>
            )}
            {isEditMode ? (
              <Select
                name="sex"
                value={formData.sex}
                onChange={handleChange}
                size="md"
                variant="flushed"
                placeholder={t("profile.sex")}
              >
                <option value="male">{t("profile.male")}</option>
                <option value="female">{t("profile.female")}</option>
              </Select>
            ) : (
              <Text fontSize="md" color="gray.500">
                {t("profile.sex")}: {profile.sex}
              </Text>
            )}
          </VStack>
        </HStack>
        <Box pt={4} width="100%">
          <Text fontSize="lg" fontWeight="semibold" mb={2}>
            {t("profile.bio")}
          </Text>
          {isEditMode ? (
            <Textarea
              name="bio"
              value={formData.bio}
              onChange={handleChange}
              size="md"
              variant="flushed"
              width="100%"
              minH="120px"
              rows={3}
              placeholder={t("profile.enter_bio")}
              resize="vertical"
            />
          ) : (
            <Text fontSize="md" color="gray.600">
              {profile.bio}
            </Text>
          )}
        </Box>
      </VStack>
      <Flex mt={8} justifyContent="flex-start">
        {isEditMode && (
          <Button onClick={saveChanges} colorScheme="teal" mr={4}>
            {t("buttons.save_changes")}
          </Button>
        )}
        <Button
          onClick={toggleEditMode}
          colorScheme={isEditMode ? "red" : "teal"}
        >
          {isEditMode ? t("buttons.cancel") : t("buttons.edit")}
        </Button>
      </Flex>
    </Box>
  );
};

export default Profile;
