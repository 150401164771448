import React from "react";
import { Text, Link } from "@chakra-ui/react";
import { useNetworkStatus } from "../hooks/useNetworkStatus";
import { Link as RouterLink } from "react-router-dom";

export const HeaderStatus: React.FC<{
  isAuthenticated: boolean;
  isEditMode: boolean;
  t: (key: string) => string;
}> = ({ isAuthenticated, isEditMode, t }) => {
  const isOnline = useNetworkStatus();

  return (
    <Text
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      fontSize="xs"
      color={isEditMode ? "white" : "gray.600"}
      whiteSpace="nowrap"
    >
      {!isOnline ? (
        "You are offline"
      ) : !isAuthenticated ? (
        <>
          {t("unauth_warning_text")}{" "}
          <Link as={RouterLink} to="/auth/sign-in" color="teal.500">
            {t("unauth_sign_in")}
          </Link>
        </>
      ) : null}
    </Text>
  );
};
